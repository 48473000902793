import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {alertLevels, alertTypes} from "../types/alert";
import {useCallback} from "react";

export const alertState = atom<alertTypes>({
    key: 'alert',
    default: {
        show: false,
    }
});

export const useRecoilGetAlert = () => {
    return useRecoilValue(alertState);
};

export const useRecoilShowAlert = () => {
    const setAlert = useSetRecoilState(alertState);

    return useCallback(({title, level = alertLevels.normal, onSubmit}: Pick<alertTypes, 'title' | 'level' | 'onSubmit'>) => {
        setAlert({
            show: true,
            title,
            level,
            onSubmit
        });
    }, []);
};

export const useRecoilCloseAlert = () => {
    const setAlert = useSetRecoilState(alertState);

    return useCallback(() => {
        setAlert({
            show: false,
        });
    }, []);
};
