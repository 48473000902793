import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {lazy, Suspense, useEffect} from "react";
import {useLoginCheck} from "../../api/user";
import {useRecoilSetUser} from "../../recoil/user";

const Login = lazy(() => import('../login').then((module) => ({default: module.Login})));
const Signup = lazy(() => import('../signup').then((module) => ({default: module.Signup})));
const SignupComp = lazy(() => import('../signup/complete').then((module) => ({default: module.SignupComp})));
const FindPw = lazy(() => import('../find').then((module) => ({default: module.Index})));

const Home = lazy(() => import('../home').then((module) => ({default: module.Home})));
const Board = lazy(() => import('../board').then((module) => ({default: module.Board})));
const BoardAll = lazy(() => import('../board/all').then((module) => ({default: module.BoardAll})));
const New = lazy(() => import('../new').then((module) => ({default: module.New})));
const News = lazy(() => import('../news').then((module) => ({default: module.News})));

const SearchActor = lazy(() => import('../search/actor').then((module) => ({default: module.SearchActor})));
const SearchNumber = lazy(() => import('../search/number').then((module) => ({default: module.SearchNumber})));

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const setUser = useRecoilSetUser();
    const {mutate} = useLoginCheck();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/login') return;

        // mutate(undefined, {
        //     onSuccess: async ({data}) => {
        //         if (!data.seq) if (location.pathname !== '/login') navigate('/login');
        //         setUser({seq: data.seq, id: data.id, email: data.email, name: data.name, role: data.role});
        //     }
        // });
    }, [location.pathname]);

    return (
        <Suspense fallback={<div/>}>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/signup" element={<Signup/>}/>
                <Route path="/signup/complete" element={<SignupComp/>}/>
                <Route path="/find/pw" element={<FindPw/>}/>

                <Route path="/new" element={<New/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/search/actor" element={<SearchActor/>}/>
                <Route path="/search/number" element={<SearchNumber/>}/>

                <Route path="/board/:category" element={<Board/>}/>
                <Route path="/board/all" element={<BoardAll/>}/>
            </Routes>
        </Suspense>
    );
}
