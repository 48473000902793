import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {modalTypes} from "../types/modal";
import {useCallback} from "react";

export const modalState = atom<modalTypes>({
    key: 'modal',
    default: {
        show: false,
        title: '',
        children: '',
        maxWidth: '',
    }
});

export const useRecoilGetModal = () => {
    return useRecoilValue(modalState);
};

export const useRecoilShowModal = () => {
    const setModal = useSetRecoilState(modalState);

    return useCallback(({title, maxWidth, children}: Pick<modalTypes, 'children' | 'title' | 'maxWidth'>) => {
        setModal({
            show: true,
            title,
            maxWidth,
            children,
        });
    }, []);
};

export const useRecoilCloseModal = () => {
    const setModal = useSetRecoilState(modalState);

    return useCallback(() => {
        setModal({
            show: false,
            title: '',
            maxWidth: '',
            children: ''
        });
    }, []);
};
