import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {userTypes} from "../types/user";
import {useCallback} from "react";

export const userState = atom<userTypes>({
    key: 'user',
    default: {
        seq: '',
        id: '',
        email: '',
        name: '',
        role: '',
    }
});

export const useRecoilGetUser = () => {
    return useRecoilValue(userState);
};

export const useRecoilSetUser = () => {
    const setUser = useSetRecoilState(userState);

    return useCallback(({seq, id, email, name, role}: Pick<userTypes, 'seq' | 'id' | 'email' | 'name' | 'role'>) => {
        setUser({
            seq,
            id,
            email,
            name,
            role,
        });
    }, []);
};


export const useRecoilDeleteUser = () => {
    const setUser = useSetRecoilState(userState);

    return useCallback(() => {
        setUser({
            seq: '',
            id: '',
            email: '',
            name: '',
            role: '',
        });
    }, []);
};
