import styled from "styled-components";
import Button, {buttonThemes} from "../button";
import {useCallback, useEffect, useState} from "react";
import {useRecoilCloseAlert, useRecoilGetAlert} from "../../../recoil/alert";
import {sizes} from "../../../types";
import {alertLevels} from "../../../types/alert";
import {colors} from "../../../styles/colors";

const Alert = () => {
    const {show, title, level, onSubmit} = useRecoilGetAlert();
    const closeAlert = useRecoilCloseAlert();
    const [isLoading, setIsLoading] = useState(false);

    const onClose = useCallback(() => {
        closeAlert();
    }, []);

    useEffect(() => {
        if (!show) setIsLoading(false);
    }, [show]);

    return (
        <Wrap className={show ? 'show' : ''}>
            <WrapInner>
                <ModalTitleWrap>
                    {
                        level ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                                 fill={level === alertLevels.normal ? colors.green["060"] : alertLevels.warning ? colors.orange["060"] : colors.red["060"]}
                                 stroke="#fff"
                                 strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-alert-circle">
                                <circle cx="12" cy="12" r="10"/>
                                <line x1="12" x2="12" y1="8" y2="12"/>
                                <line x1="12" x2="12.01" y1="16" y2="16"/>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                                 stroke="#fff"
                                 strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-alert-circle">
                            </svg>
                    }

                    {title ? title : <br/>}
                </ModalTitleWrap>

                <ModalButtonWrap>
                    <Button size={sizes.sm} theme={buttonThemes.border} onClick={onClose}>취소</Button>
                    <Button size={sizes.sm} onClick={() => {
                        setIsLoading(true);
                        if (onSubmit) onSubmit();
                    }} $isLoading={isLoading}>확인</Button>
                </ModalButtonWrap>
            </WrapInner>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: visibility 0s linear 0.2s, opacity 0.2s 0s;
  visibility: hidden;
  opacity: 0;

  &.show {
    transition: visibility 0s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
    visibility: visible;
    opacity: 1;
  }

  &.show > div {
    margin-top: 30vh;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: rgb(0, 0, 0, 0.3);
  }
`

const WrapInner = styled.div`
  width: 300px;
  margin: 50vh auto auto auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 30px 20px 20px 20px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
  transition: margin-top 0.4s;
`

const ModalTopWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ModalButtonWrap = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  & > button {
    width: 100%;
    max-width: 100px;
  }
`

export default Alert;
